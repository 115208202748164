import React, {ChangeEvent, useEffect, useState} from "react";
import {Dropdown, DropdownChangeEvent} from 'primereact/dropdown';


const SelectField = (props: {
    id: string,
    label: string,
    showLabel?: boolean,
    onChange: (id: string, value: string) => void,
    defaultValue: string | number,
    choices: any[],
    optionLabel: string,
    optionValue: string
}) => {

    const [currentValue, setCurrentValue] = useState<string>("");

    const onChange = (e: DropdownChangeEvent) => {
        setCurrentValue(e.target.value || "");

        // Il y a un comportement étrange quand valeur vide
        // Utilisation du -1 pour valeur vide remplacée à la soumission
        const value = e.target.value === "-1" ? "" : e.target.value;
        props.onChange(props.id, value)
    }

    // Il y a un comportement étrange quand valeur vide
    // Utilisation du -1 pour valeur vide remplacée à la soumission
    const getOptions = () => [{id:"-1", name: props.label},...props.choices]

    return <div className="card flex justify-content-center mb-2">
        <div className="flex flex-column gap-2">
            {props.showLabel && <label className={"niom-form-label -small"}>{props.label}</label>}
            <Dropdown placeholder={props.label}
                      optionLabel={props.optionLabel}
                      optionValue={props.optionValue}
                      value={currentValue || props.defaultValue || ""}
                      id={props.id}
                      aria-describedby="username-help"
                      options={getOptions()}
                      onChange={onChange}
                      className={"niom-form-element"}
            />
        </div>
    </div>
}

export default SelectField;