import React from "react";
import {formatTime} from "../../Helpers/Helpers";

const StopWatch = (props: {
    timer: number,
    float?: boolean
}) => {
    return <div className={`niom-stopwatch ${props.float ? '-float' : ''}`}>{formatTime(props.timer)}</div>
}

export default StopWatch;