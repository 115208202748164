export const formatTime = (timer: number): string => {
    const getSeconds: string = `0${(timer % 60)}`.slice(-2)
    const minutes: number = Math.floor(timer / 60);
    const getMinutes: string = `0${minutes % 60}`.slice(-2)
    //const getHours: string = `0${Math.floor(timer / 3600)}`.slice(-2)

    return `${getMinutes} : ${getSeconds}`
}



export const  replaceNewlineWithBr = (text: string): string => {
    return text.replace(/\n/g, '<br>');
}