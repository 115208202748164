import './assets/css/main.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/css/theme.css';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import Store from './Store/Store';
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from './reportWebVitals';
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";
import {TextForm} from "./Components/Texts/Form.component";
import CategoryForm from "./Components/Categories/Form.component";
import TextsList from "./Screens/TextsList.component";
import Niom from "./Screens/Niom.screen";
import CGUHandler from "./Screens/CGUHandler";

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";

const router = createHashRouter([
    {
        path: "/",
        element: <TextsList/>,
    },
    {
        path: "categories/add",
        element: <CategoryForm/>,
    },
    {
        path: "categories/edit/:id",
        element: <CategoryForm/>,
    },
    {
        path: "texts/add",
        element: <TextForm/>,
    },
    {
        path: "texts/edit/:id",
        element: <TextForm/>,
    },
    {
        path: "niom",
        element: <Niom/>,
    },
    {
        path: "niom/:id",
        element: <Niom/>,
    }
]);

const container = document.getElementById('root')!;
const root = createRoot(container);


const lngDefault = /^fr\b/.test(navigator.language) ? "fr" : "en";
i18next.init({
    interpolation: { escapeValue: false },
    lng: lngDefault,
    resources: {
        en: {
            common: common_en
        },
        fr: {
            common: common_fr
        },
    },
});

root.render(
    <React.StrictMode>
        <Provider store={Store.store}>
            <PersistGate persistor={Store.persistor}>
                <I18nextProvider i18n={i18next}>
                    <CGUHandler>
                        <RouterProvider router={router}/>
                    </CGUHandler>
                </I18nextProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
