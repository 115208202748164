import React from "react";
import {InputText} from "primereact/inputtext";

const TextField = (props: {
    id: string,
    label: string,
    onChange: (id: string, value: string) => void,
    defaultValue: string | number,
    type?: "text" | "number"
}) => {
    return <div className="flex justify-content-center mb-3">
        <div className="flex flex-column gap-2">
            <label className={"niom-form-element niom-form-label -small"} htmlFor={props.id}>{props.label}</label>
            <InputText type={props.type || "text"} className={"niom-form-element"} defaultValue={props.defaultValue || ""} id={props.id} aria-describedby="username-help"
                       onChange={(e) => props.onChange(props.id, e.target.value)}/>
        </div>
    </div>
}

export default TextField;