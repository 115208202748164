import React from "react";
import {Avatar} from "primereact/avatar";

const Header = (props: {
    title?: string,
    subtitle?: string,
    icon: string
}) => {

    return <div className="niom-head bg-primary-reverse">
        <div className={"mb-3"}>
            <Avatar icon={`pi ${props.icon}`} size="large" shape="circle" className={"mr-2 mt-2"} style={{background: "#4F46E5", color: "#fff"}}/>
            {props.title && <h1 className={`title-1xl font-bold text-900 mt-1 ${props.subtitle ? 'mb-1' : 'mb-0'}`}>
                {props.title}
            </h1>}
            {props.subtitle && <p className={"subtitle mt-0 mb-0"}>{props.subtitle}</p>}
        </div>
    </div>
}

export default Header;