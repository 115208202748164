import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Text, editText, selectTextById, addText} from "../../Store/Texts.slice";
import {selectCategories} from "../../Store/Texts.slice";
import {RootState} from "../../Store/RootReducer";
import {useDispatch, useSelector} from "react-redux";
import LayoutScreen from "../../Screens/Layout.screen";
import Header from "../Header/Header.component";
import TextField from "../Fields/TextField.component";
import TextareaField from "../Fields/TextareaField.component";
import {Button} from "primereact/button";
import SelectField from "../Fields/SelectField.component";
import {useTranslation} from "react-i18next";


interface TextFormProps {

}

interface TextFormData {
    title: string;
    text: string;
    categoryId: string;
}

const initialFormData: TextFormData = {
    title: "",
    text: "",
    categoryId: ""
};

export const TextForm: React.FC<TextFormProps> = () => {
    const {id} = useParams<{ id: string }>();
    const {t} = useTranslation("common");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector(selectCategories);
    const text = useSelector((state: RootState) => selectTextById(state, id ? parseInt(id) : undefined));

    const [formData, setFormData] = useState<TextFormData>(text ? text as unknown as TextFormData : initialFormData);

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const newText: Text = {
            id: text ? text.id : Math.floor(Math.random() * 1000000),
            title: formData.title,
            text: formData.text,
            categoryId: formData.categoryId ? parseInt(formData.categoryId) : null
        };

        if (id) {
            dispatch(editText(newText));
        } else {
            dispatch(addText(newText));
        }

        navigate("/");
    };

    const handleInputChange = (id: string, value: string) => {
        setFormData({...formData, [id]: value});
    };

    return (
        <LayoutScreen>
            <Header title={id ? t("text.modify_text") : t("text.create_text")} icon={"pi-align-left"}/>
            <form onSubmit={handleFormSubmit}>
                <TextField id={"title"} label={t("text.form.title")} onChange={handleInputChange} defaultValue={text?.title || ""}/>
                <TextareaField id={"text"} label={t("text.form.text")} onChange={handleInputChange} defaultValue={text?.text || ""}/>
                <SelectField showLabel={true} label={t("text.form.select_category")} optionLabel={"name"} optionValue={"id"}
                             defaultValue={text?.categoryId || null} id={"categoryId"} onChange={handleInputChange}
                             choices={categories}/>

                <div className="text-center">
                    <Button label={t("buttons.cancel")} text  icon="pi pi-cross" onClick={()=>navigate("/")} severity={"secondary"} rounded/>
                    <Button rounded className={"p-button-outlined"} label={id ? t("buttons.save") : t("buttons.create")} type="submit" icon="pi pi-check"/>
                </div>
            </form>
        </LayoutScreen>
    );
};
