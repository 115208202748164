import React from 'react';
import {useLocation} from "react-router-dom";
import {MenuItem} from "primereact/menuitem";
import {TabMenu} from "primereact/tabmenu";
import {useTranslation} from "react-i18next";

const LayoutScreen = (props: {
    children: any
}) => {

    const {t} = useTranslation("common");

    let location = useLocation();

    const items: MenuItem[] = [
        {
            label: t("layout.titles.texts"),
            icon: 'pi pi-fw pi-align-left',
            url: "#/"
        },
        {
            label: t("layout.titles.reading"),
            icon: 'pi pi-fw pi-comment',
            url: "#/niom"
        },
    ];

    const getActiveTab = (): number => {
        if(
            location.pathname.search("niom") !== -1
        ) return 1

        return 0;
    }

    /*
    <div className="layout-menu">
        <TabMenu model={items} activeIndex={getActiveTab()}/>
    </div>
     */
    return (
        <div>
            {props.children}
        </div>
    )
}

export default LayoutScreen;
