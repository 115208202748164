import React, {useEffect, useRef} from "react";
import {Text} from "../../Store/Texts.slice";
import {replaceNewlineWithBr} from "../../Helpers/Helpers";

const WordCounter = (props: {
    text: Text
}) => {
    const {text} = props;
    const outputDiv = useRef(null);
    let selectedWord = useRef(null).current;

    useEffect(() => {
        wrapText();
    }, [text])

    const wrapText = () => {

        if (!outputDiv.current || !text) return;

        outputDiv.current.innerHTML = "";

        const currentText = replaceNewlineWithBr(text.text);

        const lines = currentText.split("<br>");

        let wordNumber = 1;
        for (let l = 0; l < lines.length; l++) {
            const currentLine = lines[l];
            const words = currentLine.split(/( |'|’|\:|!|\?|\;|\.|\,)/);
            for (let i = 0; i < words.length; i++) {
                const currentWord = words[i];
                const wordSpan = document.createElement("span");
                wordSpan.textContent = currentWord;

                if(currentWord && currentWord.search(/( |'|’|\:|!|\?|\;|\.|\,)/) === -1){
                    wordSpan.classList.add("niom-word");
                    wordSpan.setAttribute("data-number", wordNumber.toString());
                    wordSpan.addEventListener("click", () => {
                        onWordClick(wordSpan);
                    });

                    wordNumber++;
                }

                outputDiv.current.appendChild(wordSpan);

            }
            outputDiv.current.appendChild(document.createElement("br"));
        }

    }

    const onWordClick = (newEl: Element) => {
        if(selectedWord) selectedWord.classList.remove("-selected");

        if(selectedWord !== newEl){
            newEl.classList.add("-selected");
            selectedWord = newEl;
        }else{
            selectedWord = null;
        }
    }

    return <div className={"text-justify mb-3 mt-3"}>
        <div className="flex justify-content-center">
            <p className={"niom-form-element niom-form-label mt-0"}>Si vous souhaitez connaitre le nombre de mots lus, cliquez sur le dernier lu pour connaître son numéro.</p>
        </div>
        <div className={"niom-read-zone -no-overflow -no-height text-left -small-text"}>
            <div className={"text"} ref={outputDiv}></div>
        </div>
    </div>
};

export default WordCounter;