import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../Store/RootReducer";
import {Text, selectTexts} from "../Store/Texts.slice";
import LayoutScreen from "./Layout.screen";
import Header from "../Components/Header/Header.component";
import TextField from "../Components/Fields/TextField.component";
import SelectField from "../Components/Fields/SelectField.component";
import { Checkbox } from 'primereact/checkbox';
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import useStopWatch from "../Hooks/useStopWatch";
import StopWatch from "../Components/StopWatch/StopWatch.component";
import WordCounter from "../Components/Texts/WordCounter.component";
import {replaceNewlineWithBr} from "../Helpers/Helpers";

const times: {value: string, label: string}[] = [
    {value: "0", label: "Aucune limite"},
    {value: "30", label: "30s"},
    {value: "60", label: "1min"},
    {value: "90", label: "1min30s"},
    {value: "120", label: "2min"},
]

const Niom = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const texts = useSelector((state: RootState) => selectTexts(state));
    const {timer , handleStart, handlePause, handleResume, handleReset, isActive} = useStopWatch();
    const [showText, setShowText] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [showStopWatch, setShowStopWatch] = useState<boolean>(false);
    const [maxTime, setMaxTime] = useState<number>(0);
    const [speed, setSpeed] = useState<number>(.1);
    const [delay, setDelay] = useState<number>(3);
    const [textToRead, setTextToRead] = useState<Text>(null);
    const outputDiv = useRef(null);
    const {t} = useTranslation("common");

    let intervalNiom = useRef<ReturnType<typeof setInterval>>(null);

    useEffect(() => {
        if (id) changeTextToRead(id);
    }, [id])

    useEffect(() => {
        if (showText) wrapText();
    }, [showText])

    useEffect(() => {
        if(maxTime && isActive && timer >= maxTime){
            setTimeout(() => {
                finish();
            }, 300);
        }
    }, [timer])


    const wrapText = (): void => {

        if (!outputDiv.current || !textToRead) return;

        outputDiv.current.innerHTML = "";

        const currentText = replaceNewlineWithBr(textToRead.text);

        const lines = currentText.split("<br>");

        for (let l = 0; l < lines.length; l++) {
            const currentText = lines[l];
            for (let i = 0; i < currentText.length; i++) {
                const charSpan = document.createElement("span");
                charSpan.textContent = currentText.charAt(i);
                charSpan.classList.add("niom-char");

                outputDiv.current.appendChild(charSpan);
            }
            outputDiv.current.appendChild(document.createElement("br"));
        }


        launch();
    }

    const launch = () => {
        setIsPlaying(true);
        if(isActive){
            handleResume();
        }else{
            handleReset();
            handleStart();
        }
        setTimeout(() => {
            intervalNiom.current = setInterval(hideNextChar, speed * 1000);
        }, delay * 1000);
    }

    const hideNextChar = () => {

        const allElements = document.querySelectorAll("span.niom-char:not(.niom-hidden), br.niom-char:not(.niom-hidden)");

        if(!allElements.length){
            return;
        }

        let charCount = 0;

        const el = allElements[charCount];
        if (el) {
            el.classList.add("niom-hidden");

            if(isTouchingLeftBoundary(el)){
                slide(el.getBoundingClientRect().top - outputDiv.current.getBoundingClientRect().top);
            }
        }
        charCount += 1;
        if (charCount >= allElements.length) {
            setTimeout(() => {
                finish();
            }, 300);
        }
    }

    const slide = (height: number) => {
        if (!outputDiv.current) return;

        const text = outputDiv.current;

        text.style.transform = "translateY(" + -height + "px)";
    }

    const pause = () => {
        handlePause();
        setIsPlaying(false);
        clearInterval(intervalNiom.current);
    }

    const finish = () => {
        handlePause();
        setIsPlaying(false);
        setIsFinished(true);
        setShowText(false);
        clearInterval(intervalNiom.current);
    }

    const reset = () => {
        handleReset();
        setIsPlaying(false);
        setShowText(false);
        setIsFinished(false);
        clearInterval(intervalNiom.current);
    }

    const changeTextToRead = (id: string): void => {
        const currentText = texts.find(x => x.id === parseInt(id));
        if (currentText) setTextToRead(currentText);
    }

    function isTouchingLeftBoundary(element: Element): boolean {
        const parentRect = element.parentElement?.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        if (!parentRect) {
            throw new Error('Element has no parent element');
        }

        return ((elementRect.left - 5) <= parentRect.left);
    }

    return <LayoutScreen>
        {!showText && !isFinished && <>
            <Header title={t("niom.header.title")} subtitle={t("niom.header.subtitle")} icon={"pi-comment"}/>
            <SelectField label={t("niom.select_text")}
                         showLabel={true}
                         optionLabel={"title"}
                         optionValue={"id"}
                         defaultValue={textToRead ? textToRead.id : ""} id={"text"}
                         onChange={(id, value) => changeTextToRead(value)} choices={texts}/>
            <TextField id={"speed"} label={t("niom.interval_hiding_chars")}
                       onChange={(id, value) => setSpeed(parseFloat(value))} defaultValue={speed} type={"number"}/>
            <TextField id={"delay"} label={t("niom.delay_option")}
                       onChange={(id, value) => setDelay(parseFloat(value))} defaultValue={delay} type={"number"}/>
            <SelectField label={t("niom.select_max_time")}
                         showLabel={true}
                         optionLabel={"label"} optionValue={"value"}
                         defaultValue={"0"} id={"time"}
                         onChange={(id, value) => setMaxTime(parseInt(value))} choices={times}/>
            <div className="flex align-items-center justify-content-center mb-3 mt-3">
                <Checkbox inputId={"stopwatch"} onChange={e => setShowStopWatch(e.checked)} checked={showStopWatch} />
                <label htmlFor="stopwatch" className="ml-2 niom-form-label">{t("niom.stopwatch")}</label>
            </div>
            <div className={" flex gap-2 justify-content-center"}>
                <Button rounded size={"small"}  link onClick={() => navigate("/")}  label={t("buttons.back_to_texts")} severity="secondary" icon="pi pi-arrow-circle-left"/>
                {textToRead && <Button rounded size={"small"}  className={"p-button-outlined"} onClick={() => setShowText(true)} icon={"pi pi-comment"} label={t("niom.start_reading")}/>}
            </div>
        </>}
        {showText && <div className={"niom-text-wrapper"}>
            <Header icon={"pi-comment"}/>
            <div className={"niom-read-zone"}>
                <div className={"text"} ref={outputDiv}></div>
            </div>
            <div className="flex justify-content-center gap-2 mt-3">
                <Button  className={"p-button-outlined"} rounded size={"small"} label={t("niom.stop")} icon="pi pi-stop" onClick={reset}  />
                {isPlaying && <Button className={"p-button-outlined"}  rounded size={"small"} label={t("niom.pause")} icon="pi pi-pause" onClick={pause}/>}
                {!isPlaying && <Button className={"p-button-outlined"}  rounded size={"small"} label={t("niom.start")} icon="pi pi-play" onClick={launch}/>}
            </div>
            {showStopWatch && <div className="flex justify-content-center gap-2 mb-3">
                <StopWatch timer={timer} float />
            </div>}
        </div>}
        {isFinished && <>
            <div className="flex flex-column justify-content-center niom-end-screen">
                <Header title={`${t("niom.header.over")} !`} icon={"pi-thumbs-up"} />
                <div className="flex justify-content-center gap-2 mb-3">
                    <StopWatch timer={timer} />
                </div>
                <div className={"text-center"}>
                    <Button link  rounded size={"small"} label={t("buttons.back")} icon="pi pi-arrow-circle-left" onClick={reset} severity={"secondary"}/>
                </div>
                <WordCounter text={textToRead} />
            </div>
        </>}
    </LayoutScreen>
}

export default Niom;