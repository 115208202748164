import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./RootReducer";
import {persistStore, persistReducer} from 'redux-persist';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'

const persistConfig = {
    key: 'root',
    storage: createIdbStorage({name: 'niomniom', storeName: 'niomniom_data'}),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
let persistor = persistStore(store)


export default {store, persistor};