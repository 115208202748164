import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../Store/RootReducer";
import {
    Text,
    deleteCategory,
    Category,
    selectCategories,
    selectTextsByCategoryId,
    deleteText
} from "../Store/Texts.slice";
import {useNavigate} from "react-router-dom";
import LayoutScreen from "./Layout.screen";
import {DataView} from 'primereact/dataview';

import {Accordion, AccordionTab} from 'primereact/accordion';
import {Button} from "primereact/button";
import Header from "../Components/Header/Header.component";
import {useTranslation} from "react-i18next";

const TextsList = () => {

    const {t} = useTranslation("common");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categories = useSelector((state: RootState) => selectCategories(state));
    const texts: Text[] = useSelector((state: RootState) => state.texts.texts.filter(x => x.categoryId !== null));
    const textsWoCateg: Text[] = useSelector((state: RootState) => selectTextsByCategoryId(state, null));

    const getTextsByCategory = (id: number): Text[] => {
        return texts.filter(x=>x.categoryId === id);
    }

    const onDeleteCategory = (id: number) => {
        if (window.confirm(t("alerts.delete_category"))) {
            dispatch(deleteCategory(id));
        }
    }
    const onDeleteText = (id: number) => {
        if (window.confirm(t("alerts.delete_text"))) {
            dispatch(deleteText(id));
        }
    }


    const exerpt = (input: string, length: number = 80): string => {
        return input.length <= length ? input : `${input.slice(0, length)}...`;
    }

    const itemTemplate = (text: Text) => {
        return <div className="col-12">
            <div className="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                <div
                    className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                    <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                        <div className="text-2xl font-bold text-900">{text.title}</div>

                        <div className="flex align-items-center gap-3">
                            {exerpt(text.text)}
                        </div>
                    </div>
                    <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                        <div className="flex flex-wrap justify-content-end gap-2">
                            <Button size={"small"} rounded title={t("buttons.read")} icon="pi pi-play"
                                    onClick={() => navigate(`/niom/${text.id}`)}/>
                            <Button size={"small"} rounded title={t("buttons.modify")} icon="pi pi-file-edit"
                                    onClick={() => navigate(`/texts/edit/${text.id}`)} className={"p-button-outlined"}/>
                            <Button size={"small"} rounded title={t("buttons.delete")} icon="pi pi-trash"
                                    className="p-button-outlined" onClick={() => onDeleteText(text.id)}
                                    severity={"danger"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <LayoutScreen>

            <Header title={t("list.title")} subtitle={t("list.subtitle")} icon={"pi-align-left"} />

            {texts.length === 0 && textsWoCateg.length === 0 && categories.length === 0  && <div className={"mb-6"}>
                <h3 className={"text-1xl text-center"}>{t("list.no_text")}</h3>
                <p className={"text-center mb-1 niom-intro"}>{t("list.no_text_explanation")}</p>
            </div>}

            {categories.length > 0 && <>
                {categories.map((category: Category) => (
                    <Accordion key={category.id} multiple className={"niom-accordeon mb-1"}>
                        <AccordionTab header={category.name}>
                            <div className="flex flex-wrap gap-2 niom-accordeon-header-actions">
                                <Button rounded size={"small"}
                                        className={"p-button-outlined"}
                                        onClick={() => navigate(`/categories/edit/${category.id}`)}
                                        icon="pi pi-file-edit"
                                        title={t("buttons.modify_category")}>
                                </Button>
                                <Button rounded size={"small"}
                                        className={"p-button-outlined"}
                                        onClick={() => onDeleteCategory(category.id)}
                                        icon="pi pi-trash"
                                        severity={"danger"}
                                        title={t("buttons.delete_category")}></Button>
                            </div>
                            <div className="card">
                                <DataView emptyMessage={t("list.no_text_in_category")} value={getTextsByCategory(category.id)} itemTemplate={itemTemplate}/>
                            </div>
                        </AccordionTab>
                    </Accordion>
                ))}
            </>}

            {textsWoCateg.length > 0 && <>
                <Accordion>
                    <AccordionTab header={t("list.no_category")}>
                        <div className="card">
                            <DataView value={textsWoCateg} itemTemplate={itemTemplate}/>
                        </div>
                    </AccordionTab>
                </Accordion>
            </>}

            <div className={`flex gap-2 row-gap-2 mt-4 mb-4 justify-content-center `}>
                <Button className={`${texts.length === 0 ? '' : 'p-button-outlined'}`} rounded size={"small"} label={t("buttons.add_text")} icon={"pi pi-plus"} onClick={() => navigate("/texts/add")}/>
                <Button className={"p-button-outlined"} rounded size={"small"} label={t("buttons.add_category")} icon={"pi pi-plus"} onClick={() => navigate("/categories/add")}
                />
            </div>

        </LayoutScreen>
    );
};

export default TextsList;
