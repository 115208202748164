import React from "react";
import { InputTextarea } from 'primereact/inputtextarea';

const TextareaField = (props:{
    id: string,
    label: string,
    onChange: (id: string, value: string) => void,
    defaultValue: string
}) => {
    return <div className="flex justify-content-center mb-3">
        <div className="flex flex-column gap-2">
            <label className={"niom-form-element niom-form-label -small"} htmlFor={props.id}>{props.label}</label>
            <InputTextarea className={"niom-form-element"} autoResize defaultValue={props.defaultValue || ""} id={props.id} aria-describedby="username-help" onChange={(e)=>props.onChange(props.id, e.target.value)}/>
        </div>
    </div>
}

export default TextareaField;