import { combineReducers } from "@reduxjs/toolkit";
import TextsSlice from "./Texts.slice";
import CGUSlice from "./CGU.slice";

const rootReducer = combineReducers({
    texts: TextsSlice.reducer,
    cgu: CGUSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;