import React from "react";
import Header from "../Components/Header/Header.component";
import {Button} from "primereact/button";
import {useDispatch, useSelector} from "react-redux";
import {changeConsent, selectConsent} from "../Store/CGU.slice";
import {RootState} from "../Store/RootReducer";
import {useTranslation} from "react-i18next";
import LanguageSwitch from "../Components/Language/LanguageSwitch.component";

type CGUProps = {
    children: JSX.Element
}

const CGUHandler: React.FC<CGUProps> = ({children}) => {

    const {t,i18n} = useTranslation("common");
    const dispatch = useDispatch();
    const consent = useSelector((state: RootState)=>selectConsent(state));

    const onConsent = () => {
        dispatch(changeConsent(true));
    }

    if(consent) return children;

    return <div>
        <Header title={t("cgu.title")} icon={"pi-bookmark"} />

        <div className="niom-cgu grid">
            <div className="col"></div>
            <div className="col-10 md:col-8">

                <p><strong>En vigueur au 10/03/2023</strong></p>

                <p>
                    L’édition et la direction de la publication de l’application NiomNiom est assurée par Geoffroy Riou, domicilié 11 rue de Champagne, Douvres la Délivrande.
                    Numéro de téléphone est 0658355379
                    Adresse e-mail geoffroy.riou.pro@gmail.com.
                </p>

                <p>
                    Le terme Application s’applique à l’application pour mobile ou tablette Android nommée « NiomNiom ». Nos règles de confidentialité expliquent quelles données nous collectons depuis l’Application et les raisons de cette collecte, comment nous utilisons / traitons ces informations et à quels tiers nous pourrions éventuellement les divulguer.
                </p>

                <h2>Accès à l’application</h2>
                <p>
                    L’application NiomNiom permet à l'Utilisateur un accès aux services suivants après achat de l'application :<br/>
                    - Création de catégories<br/>
                    - Ajout de textes<br/>
                    - Affichage des ces textes destiné à disparaitre progressivement de manière paramétrable afin de travailler la fluence de lecture
                </p>

                <p>
                    L’application est accessible à l’achat en tout lieu à tout Utilisateur ayant un accès à un terminal mobile. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.
                </p>


                <h2>Données personnelles</h2>
                <p>
                    L’application NiomNiom est exemptée de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où elle ne collecte aucune donnée concernant les Utilisateurs.
                </p>


                <h2>Permissions demandées par l’Application</h2>
                <p>
                    Aucune permission n'est demandée par l'Application.
                </p>

                <h2>Propriété intellectuelle</h2>

                <p>
                    Les marques, logos, signes ainsi que tous les contenus de l’application NiomNiom (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.
                </p>

                <p>
                    L'Utilisateur doit solliciter l'autorisation préalable de l’application NiomNiom pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus de l’application NiomNiom dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
                    Toute représentation totale ou partielle de l’application NiomNiom par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.
                    Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
                </p>

                <h2>Responsabilité</h2>
                <p>
                    Les sources des informations diffusées sur l’application NiomNiom sont réputées fiables mais l’application ne garantit pas qu’elle soit exempt de défauts, d’erreurs ou d’omissions.<br/>
                    Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, l’application NiomNiom ne peut être tenue responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, l’application ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans cette application.<br/>
                    La responsabilité de l’application ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.
                </p>

                <h2>Liens hypertextes</h2>

                <p>
                    Aucun lien hypertexte externe n'est présent sur l'Application.
                </p>

                <h2>Cookies</h2>

                <p>
                    L’Utilisateur est informé que lors de ses visites sur l’application, un cookie peut s’installer automatiquement sur son logiciel de navigation.<br/>
                    Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation de l’application NiomNiom. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.<br/>
                    L’information contenue dans les cookies est utilisée pour améliorer l’application NiomNiom.
                    En naviguant sur l’application, L’Utilisateur les accepte.<br/>
                    L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
                </p>

                <h2>Droit applicable et juridiction compétente</h2>

                <p>
                    La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.
                    Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites au début de cette politique de confidentialité.
                </p>
            </div>
            <div className="col"></div>
        </div>
        <div className="flex justify-content-center mt-5">
            <div>
                <Button icon={"pi pi-check"} label={t("cgu.accept")} onClick={onConsent}/>
            </div>
        </div>
    </div>
}

export default CGUHandler;