import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./RootReducer";

export interface Text {
    id: number;
    title: string;
    text: string;
    categoryId: number;
}

export interface Category {
    id: number;
    name: string;
}

interface TextsState {
    categories: Category[];
    texts: Text[];
}

const initialState: TextsState = {
    categories: [],
    texts: [],
};

const TextsSlice = createSlice({
    name: "textsSlice ",
    initialState,
    reducers: {
        addCategory: (state, action: PayloadAction<Category>) => {
            state.categories.push(action.payload);
        },
        editCategory: (state, action: PayloadAction<Category>) => {
            const index = state.categories.findIndex((c) => c.id === action.payload.id);
            if (index !== -1) {
                state.categories[index] = action.payload;
            }
        },
        deleteCategory: (state, action: PayloadAction<number>) => {
            const index = state.categories.findIndex((c) => c.id === action.payload);
            if (index !== -1) {
                // Suppression de la reference à la categ dnas les texts
                state.texts.map(t=>{
                    if(t.categoryId === action.payload){
                        t.categoryId = null;
                    }
                })
                // Suppression de la catégorie
                const newCategories = [...state.categories].filter(x=>x.id !== action.payload);
                state.categories = newCategories;
            }
        },
        addText: (state, action: PayloadAction<Text>) => {
            state.texts.push(action.payload);
        },
        editText: (state, action: PayloadAction<Text>) => {
            const { id } = action.payload;
            const existingText = state.texts.find((text) => text.id === id);
            if (existingText) {
                existingText.title = action.payload.title;
                existingText.text = action.payload.text;
                existingText.categoryId = action.payload.categoryId;
            }
        },
        deleteText: (state, action: PayloadAction<number>) => {
            const index = state.texts.findIndex((c) => c.id === action.payload);
            if (index !== -1) {
                // Suppression du texte
                state.texts = [...state.texts].filter(x=>x.id !== action.payload);
            }
        },
    },
});

export const { addCategory, editCategory, deleteCategory, addText, editText, deleteText } = TextsSlice.actions;


/**
 * Selecteurs
 * @param state
 */
export const selectCategories = (state: RootState) => state.texts.categories;
export const selectCategoryById = (state: RootState, id?: number) => {
    return id ? state.texts.categories.find((c) => c.id === id) : undefined;
};
export const selectTextById = (state: RootState, id: number | undefined) => {
    return state.texts.texts.find((text) => text.id === id);
};
export const selectTextsByCategoryId = (state: RootState, categoryId: number) => {
    return state.texts.texts.filter((text) => text.categoryId === categoryId);
};
export const selectTexts = (state: RootState) =>  state.texts.texts;

export default TextsSlice;
