import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addCategory,
    editCategory,
    selectCategoryById
} from "../../Store/Texts.slice";
import { Category } from "../../Store/Texts.slice";
import {useParams, useNavigate} from "react-router-dom";
import {RootState} from "../../Store/RootReducer";
import LayoutScreen from "../../Screens/Layout.screen";
import Header from "../Header/Header.component";
import TextField from "../Fields/TextField.component";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";

const CategoryForm = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation("common");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const category = useSelector((state: RootState) => selectCategoryById(state, id ? parseInt(id) : undefined));
    const [name, setName] = useState("");

    useEffect(() => {
        if (category) {
            setName(category.name);
        }
    }, [category]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newCategory: Category = {
            id: category ? category.id : Math.floor(Math.random() * 1000000),
            name
        };
        if (category) {
            dispatch(editCategory(newCategory));
        } else {
            dispatch(addCategory(newCategory));
        }

        navigate("/");
    };

    return (
        <LayoutScreen>
            <Header title={`${category ? t("category.modify_category") : t("category.create_category")}`} icon={"pi-align-left"}/>
            <form onSubmit={handleSubmit}>
                <TextField id={"name"} label={t("category.form.name")} onChange={(id,value) => setName(value)} defaultValue={name}/>
                <div className="text-center">
                    <Button label={t("buttons.cancel")} text  icon="pi pi-cross" onClick={()=>navigate("/")} severity={"secondary"} rounded/>
                    <Button rounded className={"p-button-outlined"} label={id ? t("buttons.save") : t("buttons.create")} type="submit" icon="pi pi-check"/>
                </div>
            </form>
        </LayoutScreen>
    );
};

export default CategoryForm;
