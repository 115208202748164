import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./RootReducer";

export type CGUState = {
    consent: boolean
}

const initialState: CGUState = {
    consent: false
};

const CGUSlice = createSlice({
    name: "CGUSlice ",
    initialState,
    reducers: {
        changeConsent: (state, action: PayloadAction<boolean>) => {
            state.consent = action.payload;
        },
    },
});

export const { changeConsent } = CGUSlice.actions;


/**
 * Selecteurs
 * @param state
 */
export const selectConsent = (state: RootState) => state.cgu.consent;

export default CGUSlice;
